.App {
  display: flex;
  flex: 1;
  flex-direction: column;
  text-align: center;
  background-color: #393f4b;
  min-height: 200vh;
  font-family: "Uni Sans Thin";
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}



.Top-container {
  /* background-color: #1c1c1c; */
  display: flex;
  flex: 1;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
}

.Bottom-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
}


.App-link {
  color: #61dafb;
}

.Column-container{
  display: flex;
  flex: 1;
  color: #d3d3c3;
  flex-direction: column;
  font-size: calc(10px + 4vmin);
  justify-content: space-around;
  /* border-width: 2;
  border-color: rgb(0, 255, 183);
  border-style: dashed; */
}


.Page-container {
  display: flex;
  flex: 1;
  color: #d3d3c3;
  /* border-width: 2;
  border-color: rgb(0, 255, 183);
  border-style: dashed; */
  flex-direction: row;
  font-size: calc(10px + 4vmin);
  justify-content: space-around;
  justify-items: space-around;
}

@media only screen and (max-width: 800px) {
  /* For mobile: */
    .Page-container {
      flex-direction: column;
    }
    .Column-container {
      min-height: 800px;
    }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: "Uni Sans Thin";
  src: url("https://db.onlinewebfonts.com/t/e3805dcdb7b09e2e3a60cb837d9ce9af.eot");
  src: url("https://db.onlinewebfonts.com/t/e3805dcdb7b09e2e3a60cb837d9ce9af.eot?#iefix")format("embedded-opentype"),
  url("https://db.onlinewebfonts.com/t/e3805dcdb7b09e2e3a60cb837d9ce9af.woff2")format("woff2"),
  url("https://db.onlinewebfonts.com/t/e3805dcdb7b09e2e3a60cb837d9ce9af.woff")format("woff"),
  url("https://db.onlinewebfonts.com/t/e3805dcdb7b09e2e3a60cb837d9ce9af.ttf")format("truetype"),
  url("https://db.onlinewebfonts.com/t/e3805dcdb7b09e2e3a60cb837d9ce9af.svg#Uni Sans Thin")format("svg");
}