.Projects-container{
    display: flex;
    flex: 1;
    color: #d3d3c3;
    background-color: #25272d;
    /* border-width: 2;
    border-color: rgb(118, 129, 89);
    border-style: dashed; */
    flex-direction: column;
    align-content: space-around;
    justify-content: space-around;
    padding-top: 5vh;
    padding-bottom: 5vh;
    font-size: calc(10px + 2vmin);
    padding-left: 1vw;
    padding-right: 1vw;
    border-top-left-radius: 8%;
    border-bottom-left-radius: 8%;
    height: 100vh;
}

.Title {
    font-size: calc(10px + 4vmin);
}

.WW-screen-group {
    padding-bottom: 1vh;
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-around;
    justify-items: space-around;
}

.ScreenWW {
    padding-top: 1vh;

    display: flex;
    flex: 1;
    object-fit: contain;
    max-height: 40vh;
    max-width: 16vw;
}

@media only screen and (max-width: 800px) {
    /* For mobile: */
      .WW-screen-group {
        flex-direction: column;
      }
      .Projects-container{
        border-top-left-radius: 0%;
        border-bottom-left-radius: 0%;
    }
    .ScreenWW {
        max-height: 80vh;
        width: 70vw;
        max-width: 70vw;
    }
  }