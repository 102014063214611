.Project2-container{
    flex: 1;
    display: flex;
    flex-direction: column;
    color: #d3d3c3;
    background-color: #25272d;
    /* border-width: 2;
    border-color: rgb(249, 94, 80);
    border-style: dashed; */

    height: 100vh;

    font-size: calc(10px + 2vmin);
    padding-left: 1vw;
    padding-right: 1vw;
    border-top-right-radius: 8%;
}

.Demo-gif-box {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    align-items: center;
    /* border-width: 2;
    border-color: rgb(118, 129, 89);
    border-style: dashed; */
}

.Demo-gif {
    padding-top: 1vh;
    display: flex;
    object-fit: contain;
    max-width: 35vw;
    /* border-width: 2;
    border-color: rgb(56, 21, 230);
    border-style: dashed; */
}

.Proj2-sub-box {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    /* border-width: 2;
    border-color: rgb(118, 129, 89);
    border-style: dashed; */
}

.Title {
    font-size: calc(10px + 4vmin);
}



.Blurb-box-2{
    display: flex;
    flex: .5;
    flex-direction: column;
    /* border-width: 2;
    border-color: rgb(61, 183, 0);
    border-style: dashed; */
}



@media only screen and (max-width: 800px) {
    /* For mobile: */
      .Project2-container{
        border-top-right-radius: 0%;
        background-color: #393f4b;
    }
    .Demo-gif {
        max-height: 100vh;
        max-width: 80vw;
        width: 80vw;
    }
  }