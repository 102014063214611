body {
  margin: 0;
  font-family: "Uni Sans Thin";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Uni Sans Thin";
  src: url("https://db.onlinewebfonts.com/t/e3805dcdb7b09e2e3a60cb837d9ce9af.eot");
  src: url("https://db.onlinewebfonts.com/t/e3805dcdb7b09e2e3a60cb837d9ce9af.eot?#iefix")format("embedded-opentype"),
  url("https://db.onlinewebfonts.com/t/e3805dcdb7b09e2e3a60cb837d9ce9af.woff2")format("woff2"),
  url("https://db.onlinewebfonts.com/t/e3805dcdb7b09e2e3a60cb837d9ce9af.woff")format("woff"),
  url("https://db.onlinewebfonts.com/t/e3805dcdb7b09e2e3a60cb837d9ce9af.ttf")format("truetype"),
  url("https://db.onlinewebfonts.com/t/e3805dcdb7b09e2e3a60cb837d9ce9af.svg#Uni Sans Thin")format("svg");
}