.Column-container{
    display: flex;
    flex: 1;
    color: #d3d3c3;
    flex-direction: column;
    align-content: space-around;
    justify-content: space-around;
    height: 100vh;

    /* border-width: 2;
    border-color: rgb(0, 255, 183);
    border-style: dashed; */
}

  .Name-row{
    display: flex;
    flex: .1;
    flex-direction: column;
    font-size: calc(10px + 5vmin);

    /* border-width: 2;
    border-color: rgb(180, 35, 196);
    border-style: dashed; */
  }

  .Full-stack {
    font-size: calc(10px + 3vmin);
    color: #75a4f1;
  }

  .Name-text{
    font-size: calc(10px + 5vmin);
    /* animation: 2s ease-out 0s 1 fadeIn; */
  }

  .Title-text{
    font-size: calc(10px + 3vmin);
    font-style: italic;
    /* animation: 2s ease-out .1s 1 fadeIn; */
  }

  .Badge-row{
    display: flex;
    flex: .8;
    color: #d3d3c3;

    /* border-width: 2;
    border-color: rgb(237, 180, 22);
    border-style: dashed; */

    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding-left: 3vw;
    padding-right: 3vw;
  }

  @media only screen and (max-width: 800px) {
    /* For mobile: */
      .Badge-row {
        flex-direction: column;
      }
      .Badge-group-holder {
        flex: 1;
        width: 50vw;
      }
      .Profile-picture {
        min-height: 23vh;
      }
  }


  .Badge-group-holder {
    /* animation: 2s ease-out .4s 1 fadeIn; */
    display: flex;
    flex: 2;
    flex-direction: column;
    justify-content: space-around;
    /* border-width: 2;
    border-color: rgb(214, 246, 163);
    border-style: dashed; */
    height: 20vw;
  }



  .Badge-group {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    /* border-width: 2;
    border-color: rgb(186, 162, 24);
    border-style: dashed; */
  }

  .Badge {
    display: flex;
    flex: 1;
    object-fit: contain;
    max-height: 5vh;
  }

  .Profile-picture {
    /* animation: 2s ease-out .3s 1 fadeIn; */
    display: flex;
    flex: 1;
    object-fit: contain;
    max-height: 20vw;
    min-width: 20vw;
    border-radius: 10%;
  }

  .Bio-row{
    /* animation: 2s ease-out 1s 1 fadeIn; */
    font-size: calc(10px + 2vmin);
    padding-left: 3vw;
    padding-right: 2vw;
  }

  /* inViewport animations */
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }


  a:link{
    color: #43c0df;
  }

  a:visited {
    color: #ead492;
  }

  a:hover {
    color: #b5fcb3;
  }