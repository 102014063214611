.Project3-container{
    flex: 1;
    display: flex;
    flex-direction: column;
    color: #d3d3c3;
    /* border-width: 2;
    border-color: rgb(249, 94, 80);
    border-style: dashed; */

    height: 100vh;
    font-size: calc(10px + 2vmin);
    padding-left: 1vw;
    padding-right: 1vw;
}

.Recipe-scale-box {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    align-items: center;
    /* border-width: 2;
    border-color: rgb(118, 129, 89);
    border-style: dashed; */
}

.Blurb-box {
    flex: .5;
    /* border-width: 2;
    border-color: rgb(64, 234, 214);
    border-style: dashed; */
}

.Demo-image {
    padding-top: 1vh;
    display: flex;
    object-fit: contain;
    max-width: 45vw;
    padding-bottom: 7vh;
    padding-top: 7vh;
    /* border-width: 2;
    border-color: rgb(118, 129, 89);
    border-style: dashed; */
}

.Proj3-sub-box {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-around;
    justify-items: space-around;
    align-items: center;
    /* border-width: 2;
    border-color: rgb(118, 129, 89);
    border-style: dashed; */
}

.Title {
    font-size: calc(10px + 4vmin);
}

.WW-screen-group {
    padding-bottom: 1vh;
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-around;
    justify-items: space-around;
    align-items: center;
    /* border-width: 2;
    border-color: rgb(226, 36, 223);
    border-style: dashed; */
}

.Screen {
    padding-top: 1vh;
    padding-left: 1vw;
    padding-right: 1vw;
    display: flex;
    flex: 1;
    object-fit: contain;
    max-width: 16vw;
}


@media only screen and (max-width: 800px) {
    /* For mobile: */
      .WW-screen-group {
        flex-direction: column;
      }
      .Project3-container{
        border-top-right-radius: 0%;
        background-color: #25272d;
        height: 100vh;
    }
    .Demo-image {
        max-height: 30vh;

    }
    .Screen {
        max-height: 90vh;
        width: 70vw;
        max-width: 70vw;
    }
  }